import React, { useState, forwardRef } from 'react';

import './contact.scss'

interface ApiResult {
  success: boolean;
  error?: string;
}

type ApiResultOrNull = ApiResult | null;

interface ContactProps {

}

const ENABLE_FORM = true;

const Contact = forwardRef<HTMLElement, ContactProps>((props: ContactProps, ref: React.ForwardedRef<HTMLElement>) => {

  const [ name, setName ] = useState<string>('');
  const [ email, setEmail ] = useState<string>('');
  const [ phone, setPhone ] = useState<string>('');
  const [ message, setMessage ] = useState<string>('');

  const [ loading, setLoading ] = useState<boolean>(false);
  const [ result, setResult ] = useState<ApiResultOrNull>(null);

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }

  const onEmailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const onPhoneChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  }

  const onMessageChange = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const response = await fetch(`https://www.bedican.co.uk/api/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email, phone, message })
    });

    if (response.status !== 200) {
      console.log('status is ' + response.status);
      console.log(await response.text());

      setResult({ success: false, error: 'Could not submit contact form' });
      setLoading(false);

      return;
    }

    const json = await response.json() as ApiResult;

    console.log(json);

    setResult({ success: json.success, error: json.error });

    setName('');
    setEmail('');
    setMessage('');
    setPhone('');

    setLoading(false);
  }

  return (
    <section ref={ref} className="component-contact">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Chat with us</h2>
        </div>

        <div className={`row${!ENABLE_FORM ? ' justify-content-center' : ''}`}>

          <div className={`col-lg-${ENABLE_FORM ? '5' : '8'} d-flex align-items-stretch`}>
            <div className="info">

              <div className="intro">
                <p>Ready to discuss?</p>
                {ENABLE_FORM && <p>Give us a call, drop us an email or fill in the form to help us understand your needs and objectives.</p>}
                {!ENABLE_FORM && <p>Give us a call or drop us an email to help us understand your needs and objectives.</p>}
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>contact@bedican.co.uk</p>
              </div>

              <div className="phone">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+44(0) 7980214838</p>
              </div>

              {/*}
              <div className="address">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>London, UK</p>
              </div>
              <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d59721.836709956195!2d-0.08149004112346292!3d51.51157298538048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1711625484791!5m2!1sen!2suk" width="600" height="450" style={{border:0, width:'100%', height:'290px'}} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
             */}

            </div>
          </div>

          {ENABLE_FORM &&
            <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">

              <form className="contact" method="post" onSubmit={onSubmit}>
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="name">Your Name</label>
                    <input type="text" className="form-control" required={true} value={name} onChange={onNameChange} disabled={loading} />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Business Email</label>
                    <input type="email" className="form-control" required={true} value={email} onChange={onEmailChange} disabled={loading} />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="name">Direct Phone</label>
                    <input type="phone" className="form-control" required={true} value={phone} onChange={onPhoneChange} disabled={loading} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="name">Message</label>
                  <textarea className="form-control" rows={10} required={true} value={message} onChange={onMessageChange} disabled={loading} placeholder="Briefly describe your needs"></textarea>
                </div>
                { (result !== null) && 
                  <div className="result">
                    { result.success && <div className="success">Thank you for your message, we look forward to getting back to you shortly.</div>}
                    { !result.success && <div className="error-message">{result.error}</div>}
                  </div>
                }
                { ((result === null) || (result !== null && !result.success)) &&
                  <div className="text-center"><button type="submit" disabled={loading}>Send Message</button></div>
                }
              </form>

            </div>
          }

        </div>

      </div>
    </section>
  );
});

export default Contact;